import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CellHeader from './CellHeader';

@observer
class Search extends Component {
  render() {
    const { searchHelp } = this.props;
    const { clientNumbersExist } = searchHelp;

    return (
      <thead>
        <tr className="bg-light">
          <CellHeader searchHelp={searchHelp} sortBy="name" header="Name" />
          <CellHeader
            searchHelp={searchHelp}
            sortBy="billing_number"
            header="Billing Number"
          />
          <CellHeader
            searchHelp={searchHelp}
            sortBy="shipping_number"
            header="Shipping Number"
          />
          {clientNumbersExist && (
            <CellHeader
              searchHelp={searchHelp}
              sortBy="client_number"
              header="Client Number"
            />
          )}
          <CellHeader
            searchHelp={searchHelp}
            sortBy="branch_account"
            header="Branch"
          />
          <CellHeader searchHelp={searchHelp} sortBy="email" header="Email" />
          <CellHeader searchHelp={searchHelp} sortBy="phone" header="Phone" />
        </tr>
      </thead>
    );
  }
}

export default Search;
