import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
class TableBody extends Component {
  replaceId = id => {
    const { link_template } = this.props;
    const index = link_template.indexOf(':id');
    const trunc = link_template.slice(0, index);
    return trunc + id;
  };

  render() {
    const {
      searchHelp: { searchResults, clientNumbersExist },
    } = this.props;

    return (
      <tbody>
        {searchResults.map((item, i) => {
          return (
            <tr key={item.id} className={i % 2 != 0 ? 'bg-light' : ''}>
              <td>
                <a href={this.replaceId(item.id)}>{item.name}</a>
              </td>
              <td>{item.billing_number}</td>
              <td>{item.shipping_number}</td>
              {clientNumbersExist && <td>{item.client_number}</td>}
              <td>
                {item.branch_account ? (
                  <a href={this.replaceId(item.branch_account.id)}>
                    {item.branch_account.name}
                  </a>
                ) : (
                  <p />
                )}
              </td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default TableBody;
