import React from 'react';

class Expander extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <a href="#" className="expander" onClick={onClick}>
        <img src="/assets/hnav/plus.png" size="11x11" />
      </a>
    );
  }
}

export default Expander;
