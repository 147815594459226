import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BootstrapModalHeader from './BootstrapModalHeader';
import BootstrapModalBody from './BootstrapModalBody';

@observer
class BootstrapModal extends Component {
  render() {
    const { id, header, spinner, body } = this.props;

    return (
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <BootstrapModalHeader header={header} />
            <BootstrapModalBody spinner={spinner} body={body} />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BootstrapModal;
