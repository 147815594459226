import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
class BootstrapModalHeader extends Component {
  render() {
    const { header } = this.props;

    return (
      <div className="modal-header">
        <div className="row w-100">
          <div className="col-5">
            <h1 className="modal-title">{header.title}</h1>
          </div>
          <div className="col-7">
            <div className="input-group mt-3 mb-3">
              <input
                id="search-input-modal"
                className="form-control"
                onChange={header.onChange}
                value={header.value}
                onKeyPress={header.onKeyPress}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => {
                    header.handleSearch();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center text-muted">
              <p className="font-italic ">{header.resultsFound}</p>
            </div>
          </div>
          <p className={header.invalidInput ? 'd-block text-danger' : 'd-none'}>
            Search must be more than 2 Characters
          </p>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

export default BootstrapModalHeader;
