import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import Modal from './BootstrapModal';
import SearchHelp from '../../models/Search';
import TableHead from './TableHead';
import TableBody from './TableBody';
import NoResults from './NoResults';

const searchResultsModal = 'search-results-modal';

@observer
class Search extends Component {
  searchHelp = new SearchHelp();

  @observable
  showSpinner = false;
  @action
  setSpinner = bool => {
    this.showSpinner = bool;
  };

  @observable
  searchInput = '';
  @action
  changeSearchInput = e => {
    this.searchInput = e.target.value;
  };
  staticSearchInput = '';

  @observable
  invalidInput = false;
  @action
  setInvalidInputText = bool => {
    this.invalidInput = bool;
  };

  openModal = event => {
    if (this.searchInput.length < 3) {
      this.setInvalidInputText(true);
      return;
    }
    this.setInvalidInputText(false);
    $('#' + searchResultsModal).modal('show');

    this.handleSearch();
    setTimeout(function() {
      $('#search-input-modal').focus();
    }, 200);

    // $('#search-input-modal').focus();
  };

  handleSearch = () => {
    const {
      searchResults,
      searchFor,
      updateSearchResults,
      clearSearchResults,
      resetSortDir,
    } = this.searchHelp;
    this.staticSearchInput = this.searchInput;
    this.setSpinner(true);
    clearSearchResults();
    resetSortDir();
    searchFor(this.searchInput).then(results => {
      updateSearchResults(results);
      this.setSpinner(false);
    });
  };

  handleKeyPress = event => {
    if (event.key == 'Enter') {
      this.openModal();
    }
  };

  render() {
    const { searchResults, updateSearchResults } = this.searchHelp;
    const { link_template } = this.props;
    let title = 'Results';
    let resultsFound = `${searchResults.length} ${
      searchResults.length == 1 ? 'result' : 'results'
    } for "${this.staticSearchInput}"`;

    const header = {
      title: title,
      resultsFound: resultsFound,
      onChange: this.changeSearchInput,
      value: this.searchInput,
      onKeyPress: this.handleKeyPress,
      handleSearch: this.handleSearch,
      invalidInput: this.invalidInput,
    };

    return (
      <>
        <h3>Account Name Search</h3>
        <p
          id="short-input"
          className={this.invalidInput ? 'd-block text-danger' : 'd-none'}
        >
          Search must be more than 2 Characters
        </p>
        <input
          id="search-input-main"
          onChange={this.changeSearchInput}
          value={this.searchInput}
          onKeyPress={this.handleKeyPress}
          className="form-control"
        />
        <button
          type="button"
          className="btn-sidebar-default"
          id="button-modal"
          onClick={event => this.openModal(event)}
        >
          Search
        </button>
        <Modal
          id={searchResultsModal}
          header={header}
          spinner={this.showSpinner}
          body={
            searchResults.length > 0 ? (
              <table className="table">
                <TableHead searchHelp={this.searchHelp} />
                <TableBody
                  searchHelp={this.searchHelp}
                  link_template={link_template}
                />
              </table>
            ) : (
              <NoResults showSpinner={this.showSpinner} />
            )
          }
        />
      </>
    );
  }
}

export default Search;
