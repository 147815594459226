import { observable, action } from 'mobx';

class Search {
  @observable
  searchResults = [];
  clientNumbersExist = false;
  sortedBy = '';
  dir = true;

  @action
  clearSearchResults = () => {
    this.searchResults = [];
  };

  resetSortDir = () => {
    this.sortedBy = '';
    this.dir = true;
  };

  @action
  updateSearchResults = (results, sortBy = 'name') => {
    this.clientNumbersExist = this.checkClientNumbers(results);
    if (this.sortedBy === sortBy) {
      this.dir = !this.dir;
    } else {
      this.sortedBy = sortBy;
      this.dir = true;
    }

    results = this.moveNullsToEnd(results, sortBy);
    this.searchResults = results;
  };

  checkClientNumbers = results => {
    let client_number = false;
    results.forEach((item, i) => {
      if (item.client_number) {
        client_number = true;
        return;
      }
    });
    return client_number;
  };

  moveNullsToEnd = (results, sortBy) => {
    const nullList = results.filter(item => {
      return item[sortBy] == null;
    });
    const list = results.filter(item => {
      return item[sortBy] != null;
    });
    list.sort(this.naturalSort(sortBy));
    nullList.forEach(item => {
      list.push(item);
    });
    return list;
  };

  naturalSort = sortBy => (a, b) => {
    let A;
    let B;
    if (sortBy === 'branch_account') {
      A = a[sortBy]['name'];
      B = b[sortBy]['name'];
    } else {
      A = a[sortBy];
      B = b[sortBy];
    }

    return this.dir
      ? String(A).localeCompare(
          String(B),
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
          }
        )
      : String(B).localeCompare(
          String(A),
          navigator.languages[0] || navigator.language,
          {
            numeric: true,
            ignorePunctuation: true,
          }
        );
  };

  searchFor = value => {
    return fetch(`/accounts_search?text=${value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json());
  };
}

export default Search;
