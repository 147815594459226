import React from 'react';
import AsyncSelect from 'react-select/async';

const debounce = (fn, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

class AgverdictGrowerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGrower: props.selectedGrower,
    };
  }

  debouncedLoadOptions = debounce(
    (inputValue, callback) =>
      this.loadOptions(inputValue).then(growers => callback(growers)),
    250
  );

  loadOptions = async inputValue => {
    return fetch(`/agverdict/api/growers?name_filter=${inputValue}`)
      .then(res => res.json());
  }

  growerLabel(grower) {
    let label = grower.name;
    if (!grower.branch_name) return label;
    return `${label} / ${grower.branch_name}`;
  }

  render() {
    return (
      <>
        <input type="hidden" name="account[agverdict_grower_id]" value={this.state.selectedGrower?.id} />
        <AsyncSelect
          isClearable
          defaultValue={this.state.selectedGrower}
          noOptionsMessage={() => 'Search for growers...'}
          loadOptions={this.debouncedLoadOptions}
          getOptionLabel={grower => this.growerLabel(grower)}
          getOptionValue={grower => grower.id}
          onChange={grower => { this.setState({ selectedGrower: grower }) }}
        />
      </>
    );
  }
}

export default AgverdictGrowerSelector;
