import React from 'react';

class Contractor extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <a href="#" className="contractor" onClick={onClick}>
        <img src="/assets/hnav/minus.png" size="11x11" />
      </a>
    );
  }
}

export default Contractor;
