import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
class NoResults extends Component {
  render() {
    const { showSpinner } = this.props;
    return (
      <div className="mt-3 text-center">
        {showSpinner ? (
          <div>
            <p>Searching...</p>
          </div>
        ) : (
          <p> No Results Found </p>
        )}
      </div>
    );
  }
}

export default NoResults;
