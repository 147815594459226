import React from 'react';

class Spinner extends React.Component {
  render() {
    return (
      <img className="spinner" src="/assets/hnav/spinner.gif" size="11x11" />
    );
  }
}

export default Spinner;
