import Spinner from './Spinner';
import Expander from './Expander';
import Contractor from './Contractor';
import NodeWithoutChildren from './NodeWithoutChildren';
import React from 'react';

class AgverdictLandSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      farmsLoadingSubLands: [],
      fieldsLoadingSubLands: [],
      blocksLoadingSubLands: [],
      fieldsByFarm: {},
      blocksByField: {},
      subBlocksByBlock: {},
      expandedFields: [],
      expandedBlocks: [],
    };
  }

  componentDidMount() {
    const { farms } = this.props;
    farms.forEach(farm => this.loadFieldsForFarm(farm));
  }

  loadingKey(type) {
    return `${type}sLoadingSubLands`;
  }

  setLoading(object, type) {
    const key = this.loadingKey(type);
    this.setState(currentState => ({
      [key]: [...currentState[key], object.id],
    }));
  }

  unsetLoading(object, type) {
    const key = this.loadingKey(type);
    this.setState(currentState => ({
      [key]: currentState[key].filter(each => each != object.id),
    }));
  }

  expandField(field) {
    if (!this.state.blocksByField[field.id]) this.loadBlocksForField(field);
    this.setState(currentState => ({
      expandedFields: [...currentState.expandedFields, field.id],
    }));
  }

  contractField(field) {
    this.setState(currentState => ({
      expandedFields: currentState.expandedFields.filter(
        each => each != field.id
      ),
    }));
  }

  expandBlock(block) {
    if (!this.state.subBlocksByBlock[block.id])
      this.loadSubBlocksForBlock(block);
    this.setState(currentState => ({
      expandedBlocks: [...currentState.expandedBlocks, block.id],
    }));
  }

  contractBlock(block) {
    this.setState(currentState => ({
      expandedBlocks: currentState.expandedBlocks.filter(
        each => each != block.id
      ),
    }));
  }

  loadFieldsForFarm(farm) {
    this.setLoading(farm, 'farm');
    fetch(`/agverdict/api/fields/${farm.id}`)
      .then(response => response.json())
      .then(data => {
        this.unsetLoading(farm, 'farm');
        let fieldsByFarm = this.state.fieldsByFarm;
        fieldsByFarm[farm.id] = data;
        this.setState({
          fieldsByFarm: fieldsByFarm,
        });
      });
  }

  loadBlocksForField(field) {
    this.setLoading(field, 'field');
    fetch(`/agverdict/api/blocks/${field.id}`)
      .then(response => response.json())
      .then(data => {
        this.unsetLoading(field, 'field');
        this.setState(currentState => {
          let blocksByField = currentState.blocksByField;
          blocksByField[field.id] = data;
          return { blocksByField: blocksByField };
        });
      });
  }

  loadSubBlocksForBlock(block) {
    this.setLoading(block, 'block');
    fetch(`/agverdict/api/sub_blocks/${block.id}`)
      .then(response => response.json())
      .then(data => {
        this.unsetLoading(block, 'block');
        let subBlocksByBlock = this.state.subBlocksByBlock;
        subBlocksByBlock[block.id] = data;
        this.setState({
          subBlocksByBlock: subBlocksByBlock,
        });
      });
  }

  landUrl(land) {
    const { choiceUrl } = this.props;
    const params = new URLSearchParams({
      agverdict_land_name: land.name,
      agverdict_land_id: land.id,
      agverdict_land_type: land.land_type,
    });
    return `${choiceUrl}?${params.toString()}`;
  }

  isLoading(object, type) {
    const key = this.loadingKey(type);
    return this.state[key].find(each => each == object.id);
  }

  renderToggleForField(field) {
    if (this.isLoading(field, 'field')) return <Spinner />;
    if (field.block_count == 0) return <NodeWithoutChildren />;
    if (this.state.expandedFields.includes(field.id)) {
      return <Contractor onClick={() => this.contractField(field)} />;
    } else {
      return <Expander onClick={() => this.expandField(field)} />;
    }
  }

  renderToggleForBlock(block) {
    if (this.isLoading(block, 'block')) return <Spinner />;
    if (block.sub_block_count == 0) return <NodeWithoutChildren />;
    if (this.state.expandedBlocks.includes(block.id)) {
      return <Contractor onClick={() => this.contractBlock(block)} />;
    } else {
      return <Expander onClick={() => this.expandBlock(block)} />;
    }
  }

  renderSubBlocksFor(block) {
    if (!this.state.subBlocksByBlock[block.id]) return null;
    return (
      <ul>
        {this.state.subBlocksByBlock[block.id].map(subBlock => (
          <li key={`sub-block-${subBlock.id}`}>
            <NodeWithoutChildren />
            <a href={this.landUrl(subBlock)}>{subBlock.name}</a>
          </li>
        ))}
      </ul>
    );
  }

  renderBlock(block) {
    return (
      <div>
        {this.renderToggleForBlock(block)}
        <a href={this.landUrl(block)}>{block.name}</a>
        {this.state.expandedBlocks.includes(block.id)
          ? this.renderSubBlocksFor(block)
          : null}
      </div>
    );
  }

  renderBlocksFor(field) {
    if (!this.state.blocksByField[field.id]) return null;
    return (
      <ul>
        {this.state.blocksByField[field.id].map(block => (
          <li key={`block-${block.id}`}>{this.renderBlock(block)}</li>
        ))}
      </ul>
    );
  }

  renderField(field) {
    return (
      <div>
        {this.renderToggleForField(field)}
        <a href={this.landUrl(field)}>{field.name}</a>
        {this.state.expandedFields.includes(field.id)
          ? this.renderBlocksFor(field)
          : null}
      </div>
    );
  }

  renderFieldsFor(farm) {
    if (!this.state.fieldsByFarm[farm.id]) return null;
    return (
      <ul>
        {this.state.fieldsByFarm[farm.id].map(field => (
          <li key={`field-${field.id}`}>{this.renderField(field)}</li>
        ))}
      </ul>
    );
  }

  renderFarm(farm) {
    return (
      <div>
        <h2>{farm.name}</h2>
        {this.isLoading(farm, 'farm') ? <Spinner /> : null}
        {this.renderFieldsFor(farm)}
      </div>
    );
  }

  render() {
    const { farms } = this.props;
    return (
      <ul id="agverdict-land-selector">
        {farms.map(farm => (
          <li key={`farm-${farm.id}`}>{this.renderFarm(farm)}</li>
        ))}
      </ul>
    );
  }
}

export default AgverdictLandSelector;
