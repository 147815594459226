import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
class BootstrapModalHeader extends Component {
  render() {
    const { spinner, body } = this.props;

    return (
      <div className="modal-body">
        <img
          className={'mx-auto mt-2 ' + (spinner ? 'd-block' : 'd-none')}
          src="../../assets/get_reports_accounts/hnav/spinner.gif"
        />
        {body}
      </div>
    );
  }
}

export default BootstrapModalHeader;
