import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
class CellHeader extends Component {
  render() {
    const {
      searchHelp: { searchResults, updateSearchResults },
      sortBy,
      header,
    } = this.props;
    return (
      <td
        className="cellheader"
        onClick={() => {
          updateSearchResults(Array.from(searchResults), sortBy);
        }}
      >
        <div className="w-100">
          <div className="left">
            <span className="">{header}</span>
          </div>
          <div className="right">
            <i className="fas fa-sort sortIcon" />
          </div>
        </div>
      </td>
    );
  }
}

export default CellHeader;
